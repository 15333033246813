import { render, staticRenderFns } from "./poll.answer.vue?vue&type=template&id=a66b9502&"
import script from "./poll.answer.vue?vue&type=script&lang=js&"
export * from "./poll.answer.vue?vue&type=script&lang=js&"
import style0 from "./poll.answer.vue?vue&type=style&index=0&id=a66b9502&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports